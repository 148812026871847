.spinning-preloader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2 linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
  transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.spinning-preloader-container > .spinning-preloader-elements,
.spinning-preloader-container > .spinning-preloader-elements:before,
.spinning-preloader-container > .spinning-preloader-elements:after {
  border: 3px solid transparent;
  border-radius: 50%;
}
.spinning-preloader-container > .spinning-preloader-elements {
  display: block;
  width: 150px;
  height: 150px;
  border-top-color: #44749d;
  z-index: 1500;
  opacity: 1;
  animation: spin 2s linear infinite;
}
.spinning-preloader-container > .spinning-preloader-elements:before,
.spinning-preloader-container > .spinning-preloader-elements:after {
  content: '';
  position: absolute;
}
.spinning-preloader-container > .spinning-preloader-elements:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #da222b;
  animation: spin 3s linear infinite;
}
.spinning-preloader-container > .spinning-preloader-elements:after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: #f6dc74;
  animation: spin 1.5s linear infinite;
}
.spinning-preloader-container.complete {
  opacity: 0;
  display: none;
}
@-moz-keyframes spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
@-o-keyframes spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
